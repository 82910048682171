@import "colors";

.contact {
    width: 100%;
    height: 130vh;
    background-color: $second;
    display: flex;
    justify-content: center;
    align-items: center;

    >main {
        background-color: $first;
        height: 50rem;
        width: 80%;
        padding: 3rem;
        border-radius: 30px 30px 30px 30px;

        
        >h1 {
            margin-top: 2rem;
            font-size: 60px;
            display: flex;
            //justify-content: center;
            justify-content: center;
            
        }

        >form {
            height: 80%;
            width: 100%;
            margin: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
             
            > div {
                padding-bottom: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 80%;
                padding-bottom: 2.5rem;
                padding-left: 2rem;

                > label{

                    font: 400 1.2rem $text1;
                    width: 20% ;
                }

                > input{
                    align-self: stretch;
                    border-radius: 2px;
                    width: 80%;
                    padding: 1rem;
                    border: 1px solid rgba(25,71,126,0.6);
                    outline: none;
                
                }
                
                .message_value{
                    
                    align-self: normal;
                    height: 12rem;
                    
                align-items: flex-start;
                    
                    overflow-wrap: unset;

                }

                
                #send{

                    &:hover{
                        background-color: $correct;
                        opacity: 0.8;
                        font-size: 25px;
                        cursor: pointer;
                        color: black;
                        box-shadow: 7px 7px 5px 0 #03C03C66 ;
                        }    
                }
                
                #clear{
                    &:hover{
                        background-color: $wrong;
                        opacity: 0.8;
                        font-size: 25px;
                        cursor: pointer;
                        color: black;
                        box-shadow: 7px 7px 5px 0 #FF033E88 ;
                        }    
                }

                > button{
                    margin-top: 2rem;
                    margin-left: 3rem;
                    padding: 0.8rem;
                    align-items: bottom;
                    border: none;
                    border-radius: 8px;
                    background-color: $third;
                    color: $first;
                    width: 200px;
                    font: 500 1rem $text1;
                
                }
                
                
                
            }


        }
        
    }
}