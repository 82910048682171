@import "colors";

.home {
    width: 100%;
    height: 100vh;
    background-color: $second;
    border-radius: 0 0 200px 200px;

    > main{
        
        width: 100%;
        height: 200px;
        direction: rtl;
        background:linear-gradient(to left, rgba(0,54,0,0), rgba(0,0,0,1));
        background-size: 600px;
        background-repeat: no-repeat;
        height: 20%;
        padding: 0rem;
        position: absolute;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: top;


        > h1 {
            font: 400 6rem "Roboto";
            color: $first;
            margin-left:1rem;
          }
        > p {
          margin-left:1.6rem;
          font: 900 1rem "Roboto";
          color: $first;
      }
    }


    &::before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 0 0 200px 200px;
    
        background: no-repeat center/auto url("../assets/images/1.jpg");
        background-size: 100%;
    }
}



.home2 {
    width: 100%;
    height: 100vh;
    background-color: $second;
    padding: 500px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -200px;
  
    > img {
      width: 30%;
      animation: imgUp 0.7s linear infinite alternate;
    }
  
    > div {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem;
      height: 100vh;
  
      p {
        color: $first;
        letter-spacing: 2px;
        word-spacing: 5px;
        font: 100 1.2rem $text2;
      }
    }
  }
  
  @keyframes imgUp {
    to {
      transform: translateY(-10px);
    }
  }

.home3 {
    width: 100%;
    height: 120vh;
    background-color: $second;
    margin-top: -200px;
    display: flex;
  
    > div {
      width: 70%;
      height: 100vh;
      background-color: $first;
      border-radius: 0 200px 200px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;
  
      > h1 {
        text-transform: uppercase;
        border-bottom: 2px solid $dark;
        padding: 0.5rem;
        width: 15rem;
        color: $dark;
      }
  
      > p {
        padding: 5rem;
        letter-spacing: 1px;
        word-spacing: 5px;
        line-height: 170%;
        font: italic 100 1.2rem $text2;
      }
    }
  }



.home4 {
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: flex-end;
  
    > div {
      background-color: $third;
      border-radius: 200px 0 0 200px;
      width: 70%;
      height: 70vh;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      > h1 {
        color: $first;
        text-transform: uppercase;
        width: 140px;
        border-bottom: 2px solid $first;
        padding: 0.5rem;
      }
  
      > article {
        display: flex;
        margin: 2rem 0;
        flex-wrap: wrap;
        justify-content: center;
        > div {
          animation: imgUp 0.7s linear infinite alternate;
          margin: 1rem;
          height: 10rem;
          width: 10rem;
          background-color: $first;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: $dark;
          cursor: pointer;
          transition: all 0.5s;
          &:hover {
            filter: invert(1);
          }
  
          svg {
            font-size: 4rem;
          }
          p {
            font: 900 1.2rem $text2;
          }
        }
      }
    }
  }
  