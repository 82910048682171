@import "colors";

footer{
    background-color: $first;
    height: 100%;
    color: $dark;
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 2rem;    

    >div{
        width: 100wx;
        display: flex;
        flex-direction: column;
        justify-content: center;    

        > h1{
            font: 700 2rem $text2;
            text-transform: uppercase;
            color: $third;
            }

            >p{
                font:500 1.2rem $text1;
            }

            > h5{

                display: flex ;
                font: 800 2rem $text1;
                margin: 1rem 0;
                margin-left: 12rem;
                justify-content: left;
                
            }

            >div{
                width: 500px;
                display: flex;
                flex-direction: rows;
                //align-items: center;
                
             >div {
                padding: 0.5rem;

                > a{
                    
                padding-left: 1rem;
                    color: $dark;
                    font: 800 1rem $text2;

                    &:hover {
                        color: $third;
                        
                    }
                }
            }
            }
    }
}