$first:white;
$second:#090c31;
$third: #55f;
$dark: #333;

$correct: #03C03C;

$wrong: #FF033E;

$text1: "Roboto";
$text2: cursive;

