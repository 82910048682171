@import "colors";

nav {
    width: 100%;
    padding: 1rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: $first;
    z-index: 8;
    top: 0%;
    
    > h1{
        text-transform: uppercase;
    }

    > main{
        width: 80%;
        display: flex;
        justify-content: flex-end;

        > a{
            color: $dark;
            margin: 1rem;

            &:hover{
                color: $third;
            }
        }
    }
}